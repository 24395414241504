import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Principal from '../views/Cards.vue'
import jwtDecode from 'jwt-decode';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'principal',
    component: Principal
  },
  {
    path: '/mantenedores',
    name: 'mantenedores',
    component: () => import(/* webpackChunkName: "mantenedores" */ '../views/Mantenedores'),
    beforeEnter: async (to, from, next) => {
      const decodedToken = jwtDecode(localStorage.getItem('token'));
      if(decodedToken.is_admin){
        next()
      }else{
        router.push("/").catch(() => {});
      }
    }
    
  },
  {
    path: '/mantenedores/registro-usuarios',
    name: 'registro-usuario',
    component: () => import(/* webpackChunkName: "registro-usuarios" */ '../components/mantenedores/usuarios/RegistroUsuario')
  },
  {
    path: '/mantenedores/editar-usuario',
    name: 'editar-usuario',
    component: () => import(/* webpackChunkName: "editar-usuarios" */ '../components/mantenedores/usuarios/EditarUsuario')
  },
  {
    path: '/caudal-diario',
    name: 'caudal-diario',
    component: () => import(/* webpackChunkName: "caudal-diario" */ '../components/caudales/CaudalDiario')
  },
  {
    path: '/caudal-mensual',
    name: 'caudal-mensual',
    component: () => import(/* webpackChunkName: "caudal-mensual" */ '../components/caudales/CaudalMensual')
  },
  {
    path: '/precipitaciones',
    name: 'precipitaciones',
    component: () => import(/* webpackChunkName: "precipitaciones" */ '../components/caudales/PrecipitacionesMaximas')
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: () => import(/* webpackChunkName: "mapa" */ '../components/mapa/MapaEstaciones')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  /*{
    path: '/principal',
    name: 'principal',
    component: Principal
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if ( to.name !== 'login' && !localStorage.getItem('token')){
    next({
      path: 'login',
      replace: true
    })
  } else {
    next();
  }
})
export default router
