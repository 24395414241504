<template>
  <v-container>
    <v-row style="padding-top: 30px; padding-bottom: 10px">
      <v-col justify="center" align="center">
        <div class="titleHome">
          Seleccionar el tipo de reporte que desea revisar
        </div>
      </v-col>
    </v-row>

    <v-item-group justify="center" align="center">
      <v-row class="text-center text-justify" justify="center" align="center">
        <!-- <v-col class="ml-5 mr-5" cols="6" style="padding-right: 80px; padding-top: 40px;" justify="right" align="right"> -->
        <v-col cols="4" justify="right" align="right">
          <v-card
            d-flex
            align-center
            justify-center
            text-justify
            outlined
            color="#fafbfe"
            width="400"
            min-height="320"
          >
            <v-col cols="12" justify="center" align="center">
              <v-avatar size="130">
                <img src="../assets/forest.png" />
              </v-avatar>
            </v-col>
            <v-card-text>
              <v-row>
                <v-col justify="center" align="center" style="padding: 0px">
                  <div class="titleCard">Caudales</div>
                </v-col>
                <v-col
                  style="padding-top: 0px"
                  cols="12"
                  justify="center"
                  align="center"
                >
                  <div class="descriptionCard">
                    Información oficial hidrometeorológica y de calidad de aguas
                    sobre reportes fluviométricos
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mb-6" cols="6" justify="right" align="right">
                  <v-btn
                    style="background-color: #08697e; color: white"
                    class="ma-2"
                    @click="abrirModal('mensual')"
                  >
                    Mensual
                  </v-btn>
                </v-col>
                <v-col class="mb-6" cols="6" justify="left" align="left">
                  <v-btn
                    style="background-color: #08697e; color: white"
                    class="ma-2"
                    @click="abrirModal('diario')"
                  >
                    Diario
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col class="ml-5 mr-5" cols="6" style="padding-left: 80px; padding-top: 40px;" justify="left" align="left"> -->
        <v-col cols="4" justify="left" align="left">
          <v-card
            d-flex
            align-center
            justify-center
            text-justify
            outlined
            color="#fafbfe"
            width="400"
            min-height="320"
          >
            <v-col cols="12" justify="center" align="center">
              <v-avatar size="130">
                <img src="../assets/rain.png" />
              </v-avatar>
            </v-col>
            <v-card-text>
              <v-row>
                <v-col
                  style="padding: 0px"
                  class="mb-2"
                  cols="12"
                  justify="center"
                  align="center"
                >
                  <div class="titleCard">Precipitaciones anuales</div>
                </v-col>
                <v-col
                  style="padding-top: 0px"
                  cols="12"
                  justify="center"
                  align="center"
                >
                  <div class="descriptionCard">
                    Información oficial hidrometeorológica y de calidad de aguas
                    sobre reportes fluviométricos
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mb-6" cols="12" justify="center" align="center">
                  <v-btn
                    style="background-color: #08697e; color: white"
                    @click="abrirModal('precipitaciones')"
                  >
                    Anual
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" justify="right" align="right">
          <v-card
            d-flex
            align-center
            justify-center
            text-justify
            outlined
            color="#fafbfe"
            width="400"
            min-height="320"
          >
            <v-col cols="12" justify="center" align="center">
              <v-avatar size="130">
                <img src="../assets/gps.png" />
              </v-avatar>
            </v-col>
            <v-card-text>
              <v-row>
                <v-col justify="center" align="center" style="padding: 0px">
                  <div class="titleCard">Mapa de estaciones</div>
                </v-col>
                <v-col
                  style="padding-top: 0px"
                  cols="12"
                  justify="center"
                  align="center"
                >
                  <div class="descriptionCard">
                    Ubicación geografica de las estaciones
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mb-6" cols="12" justify="center" align="center">
                  <v-btn
                    style="background-color: #08697e; color: white"
                    class="ma-2"
                    href="/mapa"
                  >
                    Ver
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-item-group>
    <modalHome v-model="showModal" :title="titulo" :tipo="tipo" lazy>
    </modalHome>
  </v-container>
</template>
<script>
import modalHome from "@/components/componentes/ModalHome.vue";

export default {
  components: {
    modalHome,
  },
  /* name: "ProjectsPage", */
  data() {
    return {
      showModal: false,
      tipo: "",
      titulo: "",
    };
  },
  mounted() {},
  methods: {
    handleClick(value) {
      switch (value) {
        case 1:
          this.$router.push("/compensaciones");
          break;
        case 2:
          this.$router.push("/metodologia");
          break;
        case 3:
          this.$router.push("/mantenedores");
          break;
        default:
          break;
      }
    },
    abrirModal(tipo) {
      this.tipo = tipo;
      switch (tipo) {
        case "mensual":
          this.titulo = "Reporte caudales medios mensuales";
          break;
        case "diario":
          this.titulo = "Reporte altura y caudal instantáneo (Diario)";
          break;
        case "precipitaciones":
          this.titulo = "Reporte Precipitaciones Máximas Anuales en 24 horas";
          break;
        default:
          break;
      }
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
.titleCard {
  font-size: 140%;
  color: #2b373d;
  font-weight: bold;
}
.titleHome {
  font-size: 100%;
  color: #243244;
  font-weight: bold;
}
.descriptionCard {
  margin-top: 5%;
  font-size: 90%;
  color: #2b373d;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(255, 255, 255, 0.8);
}
.v-card.on-hover.theme--dark > .v-card__text {
  color: #000;
}
</style>
