<template>
    <v-row>
        <v-col cols="12" class="pb-0 pt-0 mt-3">
            <v-select
                v-model="region"
                :items="regiones"
                label="Regiones"
                outlined
                dense
                item-text="region"
                item-value="region"
                @change="$emit('cambio-seleccion', region)"
            ></v-select>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios'

    export default {
        data () {
            return {
                regiones: [],
                region: 'Seleccionar Región',
            }
        },
        mounted() {
            this.getRegiones()
        },
        methods: {
            getRegiones() {
                axios.get(process.env.VUE_APP_URL + '/proyecto/regiones')
                .then(response => {
                    this.regiones = response.data  
                    this.regiones.unshift({'region': 'Seleccionar Región', 'dcount': 0})
                });
            },
        }
    }
</script>