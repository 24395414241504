<template>
  <v-dialog v-model="showModal" max-width="500">
    <v-card>
      <v-toolbar color="#08697e" dark>
        {{ title }}
      </v-toolbar>

      <v-card-text class="mt-4">
        <div class="text-center">
          <v-row class="text-center">
            <v-col cols="6">
              <selectRegion ref="selectRegion"></selectRegion>
            </v-col>
            <v-col cols="6">
              <selectEstacion
                :region="region"
                ref="selectEstacion"
              ></selectEstacion>
            </v-col>
          </v-row>
        </div>
        <label style="color: red" v-show="errorSeleccion"
          >*Debe seleccionar una region y una estacion</label
        >
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          style="background-color: #08697e; color: white"
          @click="redirigirVista"
          >Buscar</v-btn
        >
        <v-btn
          style="background-color: #08697e; color: white"
          @click="showModal = false"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import router from "@/router";
import SelectRegion from "@/components/componentes/SelectRegion.vue";
import SelectEstacion from "@/components/componentes/SelectEstacion.vue";
export default {
  props: {
    title: {
      type: String,
      default: "Modal",
    },
    tipo: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SelectRegion,
    SelectEstacion,
  },
  data() {
    return {
      region: "Seleccionar Región",
      estacion: "Seleccionar Estación",
      idEstacion: "",
      errorSeleccion: false,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    showModal(newValue, oldValue) {
      this.$nextTick(() => {
        this.$refs.selectRegion.$on("cambio-seleccion", (nuevoValor) => {
          this.region = nuevoValor;
        });
      });
    },
    region(newValue, oldValue) {
      this.$refs.selectEstacion.$on("cambio-seleccion", (nuevoValor) => {
        this.estacion = nuevoValor;
      });
    },
  },
  methods: {
    redirigirVista() {
      if (this.region != "Seleccionar Región" && this.estacion != "Seleccionar Estación") {
        switch (this.tipo) {
          case "mensual":
            router.push({
              name: "caudal-mensual",
              params: { region: this.region, estacion: this.estacion },
            });
            break;
          case "diario":
            router.push({
              name: "caudal-diario",
              params: { region: this.region, estacion: this.estacion },
            });
            break;
          case "precipitaciones":
            router.push({
              name: "precipitaciones",
              params: { region: this.region, estacion: this.estacion },
            });
            break;
          default:
            break;
        }
      } else {
        this.errorSeleccion = true;
      }
    },
  },
};
</script>
