import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import estacionModule from "./estacion/estacionModule";
import caudalModule from "./caudal/caudalModule";
import precipitacionModule from "./precipitacion/precipitacionModule";
import loginModule from "./login/LoginModule";

Vue.use(Vuex)

// Make Axios good with django csrf
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export default new Vuex.Store({
    modules: {
        loginModule,
        estacionModule,
        caudalModule,
        precipitacionModule
    }
})