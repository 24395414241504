<template>
  <div>
    <v-container fluid class="mt-6">
      <!-- Login popup modal -->
      <div class="ml-6">
        <v-col justify="center" align="center">
          <v-card width="400">
            <v-card-title>Iniciar sesión</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-text-field v-model="email" label="Correo electrónico" hint="Por ejemplo: email@dss.cl"
                prepend-icon="mdi-email" :error-messages="usernameError"></v-text-field>
              <!-- Allowing user to toggle visibility of password -->
              <v-text-field v-model="password" :type="show1 ? 'text' : 'password'" name="input-10-1"
                @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" label="Contraseña"
                prepend-icon="mdi-account-key" @keyup.enter="authenticate()"
                :error-messages="passwordError"></v-text-field>
            </v-card-text>
            <label v-if="datosInvalidos" class="label-error">Credenciales invalidas</label>
            <v-divider></v-divider>
            <v-card-actions>
              <v-col style="padding:0px;" justify="center" align="center">
                <v-btn color="success" @click.prevent="authenticate" :loading="loading">Iniciar sesión</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import jwtDecode from 'jwt-decode';
export default {
  data() {
    return {
      loading: false,
      show1: false,
      email: "",
      password: "",
      dialog: false,
      passwordError: '',
      usernameError: '',
      datosInvalidos: false,
    };
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.jwt

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token" + token
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
  computed: {
    ...mapState({
      endpoint: (state) => state.loginModule.endpoints.obtainJWT,
    }),
  },
  methods: {
    authenticate() {
      this.loading = true
      this.usernameError = ''
      this.passwordError = ''
      //this.datosInvalidos = true
      let continuar = true

      if (this.email == '') {
        this.usernameError = 'Usuario invalido'
        continuar = false;
      }
      if (this.password == '') {
        this.passwordError = 'Debe ingresar contraseña'
        continuar = false;
      }
      if (continuar) {
        const payload = {
          email: this.email,
          password: this.password,
        };

        axios
          .post(this.endpoint, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => {
            this.$store.commit("updateToken", response.data.token);
            localStorage.setItem('refresh', response.data.refresh)
            if (localStorage.getItem('token')) {
              this.$store.commit("setAuthUser", {
                authUser: response.data,
                isAuthenticated: true,
              });
              this.loading = false
              this.registroAuditoria()
              this.$router.push({ name: "principal" });
            }

          })
          .catch((error) => {
            this.loading = false
            this.datosInvalidos = true
            //console.log(error);
            //console.debug(error);
            //console.dir(error);
          });
      } else {
        this.loading = false
      }
    },
    registroAuditoria() {
      const decodedToken = jwtDecode(localStorage.getItem('token'));
      const browserVersion = navigator.userAgent;
      this.getIPAddress()
        .then(ipAddress => {
          const data = {
            usuario: decodedToken.name,
            ip: ipAddress,
            navegador: browserVersion,
          }
          axios.post(process.env.VUE_APP_URL + '/proyecto/api/auditoria', data)
            .then(response => {
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    getIPAddress() {
      return fetch('https://api.ipify.org?format=json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch IP address');
          }
          return response.json()
            .then(data => data.ip);
        });
    }
  },
};
</script>
  
<style lang="css" scoped>
.primary_bg {
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
}

.v-text-field {
  width: 400px;
}

.label-error {
  color: red;
}
</style>
  