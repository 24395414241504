<template>
  <div>
    <header class="primary-header">
      <nav class="navbar container">
        <a href="/" class="logo"><img src="../assets/MicrosoftTeams-image.png" height="40" /></a>

        <ul class="nav-list" v-if="this.$route.name !== 'login'">
          <li>
            <router-link :to="{ name: 'principal' }" class="nav-link">Inicio</router-link>
          </li>
          <li v-show="admin">
            <router-link :to="{ name: 'mantenedores' }" class="nav-link">Mantenedores</router-link>
          </li>
        </ul>
        <div v-if="this.$route.name !== 'login'">
          <span>{{ nombreUsuario }} &nbsp;&nbsp;</span> |
          <v-btn text @click="logout">
            <v-icon>mdi-exit-to-app</v-icon> cerrar sesión
          </v-btn>
        </div>
      </nav>
    </header>
    <!-- <section class="hero"></section> -->
  </div>
</template>
<script>
import jwtDecode from 'jwt-decode';
import axios from 'axios'
export default {
  data: () => ({
    show: true,
    admin: null,
    nombreUsuario: null,
    alerta: false,
    intervalo: null,
    proximoAcerrar: true
  }),
  watch: {
    '$route': {
      handler() {
        if (localStorage.getItem('token')) {
          this.obtenerInformacionUsuario();
        }
      },
      immediate: true  // Ejecutar el watcher cuando se monte el componente
    }
  },
  created() {
    this.inicioIntervalo()
  },
  methods: {
    inicioIntervalo(){
      this.intervalo = setInterval(this.checkTokenExpiration, 5000);
    },
    detenerIntervalo() {
      clearInterval(this.intervalo)
    },
    async checkTokenExpiration(){
      if(localStorage.token){
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        const currentDate = new Date();
        const tokenExpirationDate = new Date(decodedToken.exp * 1000);
        if(currentDate.getTime() >= (tokenExpirationDate.getTime() - 30000) && this.proximoAcerrar){
          this.detenerIntervalo()
          let timerInterval
          await this.$swal({
            title: '¡Sesión próxima a expirar!',
            html: 'Esta alerta se cerrará en <b></b> segundos.',
            timer: 30000,
            timerProgressBar: true,
            confirmButtonText: 'Mantener sesión activa',
            confirmButtonColor: '#08697e',
            allowOutsideClick: false,
            didOpen: () => {
              const b = this.$swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                const timerLeftInSeconds = Math.ceil(this.$swal.getTimerLeft() / 1000) // Convertir a segundos
                b.textContent = timerLeftInSeconds
              }, 1000)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            if(result.isConfirmed){
              const payload = {
                refresh: localStorage.getItem('refresh')
              }
              axios
              .post(process.env.VUE_APP_URL + '/proyecto/api/login/refresh', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
              .then((response) => {
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                localStorage.setItem('token', response.data.access)
                localStorage.setItem('refresh', response.data.refresh)
                this.proximoAcerrar = true
                this.inicioIntervalo()
              })
            }
            if (result.dismiss === 'timer') {
              this.proximoAcerrar = false
              this.inicioIntervalo()
            }
          })
        }
        if(currentDate.getTime() > tokenExpirationDate.getTime() && !this.alerta){
          this.isAlertShown = true;
          this.detenerIntervalo()
          await this.$swal({
            title: '¡Sesión expirada!',
            text: 'Tu sesión ha expirado. Inicia sesión nuevamente.',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#08697e',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.logout()
            } 
          })
        }
      }
    },
    obtenerInformacionUsuario() {
      const decodedToken = jwtDecode(localStorage.getItem('token'));
      this.admin = decodedToken.is_admin
      this.nombreUsuario = decodedToken.name
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "login" });
    },
  },
  async mounted() {
    if (localStorage.getItem('token') != null) {
      await axios.get(process.env.VUE_APP_URL + '/proyecto/api/sesion',
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => {
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit("logout");
          this.$router.push({ name: "login" }).catch(()=>{});
        }
      });
    }
  }
};
/* const isScrolling = () => {
          const headerEl = document.querySelector('.primary-header')
          let windowPosition = window.scrollY > 250
          headerEl.classList.toggle('active', windowPosition)|
        }
        window.addEventListener('scroll', isScrolling) */
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

/* End general styles */
.primary-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  transition: background-color 0.5s;
}

.container {
  max-width: 120rem;
  margin-inline: auto;
  padding-inline: 2.5rem;
}

.navbar {
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  transition: height 0.25s;
  background: #eaebeb;
}

.logo {
  font-size: 1.4rem;
  font-weight: 900;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  margin-top: 0.2rem;
  margin-left: 2rem;
  margin-right: auto;
}

.nav-link {
  transition: color 0.25s;
  color: #2b373d;
}

.nav-link:hover {
  color: #d3d3d3;
}

.button {
  display: inline-block;
  background-color: #fff;
  color: #313131;
  padding: 0.8rem 2rem;
  border-radius: 2rem;
  transition: background-color 0.25s;
}

.button:hover {
  background-color: #d3d3d3;
}

header.active {
  background-color: #fff;
  box-shadow: 0 3px 1rem rgba(0, 0, 0, 0.1);
}

header.active .navbar {
  height: 6rem;
}

header.active .logo,
header.active .nav-link {
  color: #313131;
}

header.active .nav-link:hover {
  color: #808080;
}

header.active .button {
  background-color: #313131;
  color: #fff;
}

header.active .button:hover {
  background-color: #151515;
}

/* Hero Demo Content*/
/* .hero {
	width: 100%;
	height: 7vh;
	background-color: white;
} */

/* .hero::after {
	content: '';
	width: inherit;
	height: inherit;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
} */
</style>
