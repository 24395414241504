import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'

import GmapVue from 'gmap-vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "axios";

axios.interceptors.request.use(function (config) {
  //const token = store.getState().session.token;
  const token = localStorage.getItem('token')
  config.headers.Authorization = 'Bearer ' + token;
  return config;
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueSweetalert2);
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBk3Qh7bFE8wO-ecyhVwMy7j1OincfuGm4',
    libraries: 'places',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})