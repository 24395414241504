<template>
  <v-app>
    <div id="app" data-app>
      <nav-bar></nav-bar>
      <section class="demo-content">
        <router-view/>
      </section>
    </div>
  </v-app>
</template>
<script>
import NavBar from "./views/Navbar.vue";
export default {
  name: 'App',
  components: { NavBar },
}
</script>
<style scss>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto:wght@300&display=swap');
.demo-content {
  padding-top: 80px;
	width: 100%;
	background-color: #fff;
}
#app {
  font-family: 'Poppins', sans-serif;
	font-size: 1.0rem;
	color: black;
}
body {
	font-family: 'Poppins', sans-serif;
	font-size: 1.0rem;
	color: #fff;
}
</style>
