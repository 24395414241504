import Vue from 'vue'
export default {
    state: () =>  ({
      authUser: {},
      isAuthenticated: false,
      jwt: localStorage.getItem('token'),
      APIData:'',
      endpoints: {
        // Change these with your endpoints.
        obtainJWT: process.env.VUE_APP_URL + '/proyecto/api/login',
        refreshJWT: process.env.VUE_APP_URL + '/proyecto/api/login/refresh',
        baseUrl: process.env.VUE_APP_URL
      }
    }),

    mutations: {
      initializeStore(state){
        if (localStorage.getItem('token')){
          state.jwt = localStorage.getItem('token')
          state.isAuthenticated = true
        }else{
          state.jwt = null
          state.isAuthenticated = false
        }
      },
      setAuthUser(state, {
        authUser,
        isAuthenticated
      }) {
        Vue.set(state, 'authUser', authUser)
        Vue.set(state, 'isAuthenticated', isAuthenticated)
      },
      //You should find a better alternative to storing in local storage
      updateToken(state, newToken) {
        localStorage.setItem('token', newToken);
        state.jwt = newToken;
        state.isAuthenticated = true
      },
      removeToken(state) {
        localStorage.removeItem('token');
        state.jwt = null;
        state.isAuthenticated = false
      },
      logout(state){
        this.commit("removeToken")
        state.authUser={}
      }
    }
  }