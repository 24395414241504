<template>
    <v-row>
        <v-col cols="12" class="pb-0 pt-0 mt-3">
            <v-select
                v-model="estacion"
                :items="estaciones"
                item-text="nombre"
                item-value="estacion"
                label="Estaciones"
                outlined
                dense
                @change="$emit('cambio-seleccion', estacion)"
                return-object
            ></v-select>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios'

    export default {
        props: {
            region: {
                type: String,
            }
        },
        data () {
            return {
                estaciones: [],
                estacion: 'Seleccionar Estación',
            }
        },
        watch: {
            region (newValue, oldValue) {
                this.getEstaciones(newValue)
            }
        },
        mounted(){
            this.getEstaciones(this.region)
        },
        methods: {
            getEstaciones(region){
                if (region == 'Seleccionar Región') {
                    region = ''
                    this.estacion = 'Seleccionar Estación'
                    this.estaciones = ['Seleccionar Estación']
                }else {
                    this.estaciones = []
                    axios.get(process.env.VUE_APP_URL + '/proyecto/estaciones?region=' + encodeURIComponent(region))
                    .then(response => {
                        response.data.map( estacion => {
                            this.estaciones.push(estacion)
                        })
                        this.estaciones.unshift('Seleccionar Estación')
                    });
                }
            },
        }
    }
</script>